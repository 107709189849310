<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <b-row>
    <b-col md="3">
      <Fieldset id="salesChannel" title="Sales Channel">
        <SaleChannels />
      </Fieldset>
    </b-col>
    <b-col md="9">
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-shorthand */
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import SaleChannels from './components/SaleChannels.vue'

export default {
  components: {
    BRow,
    BCol,
    SaleChannels,
  },
}
</script>
