<template>
  <b-list-group flush>
    <b-list-group-item
      v-if="connectedShopee"
      to="/channel/shopee"
      class="d-flex align-items-center"
      :class="{'active': currentChannel === 'shopee'}"
    >
      <b-aspect
        aspect="16:9"
        class="d-flex align-items-center"
        style="min-width: 60px; max-width: 60px;"
      >
        <b-img-lazy
          src="@/assets/images/salesChannel/shopee.svg"
          fluid
        />
      </b-aspect>
      <div class="ml-2">
        Shopee
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="connectedLazada"
      to="/channel/lazada"
      class="d-flex align-items-center"
      :class="{'active': currentChannel === 'lazada'}"
    >
      <b-aspect
        aspect="16:9"
        class="d-flex align-items-center"
        style="min-width: 60px; max-width: 60px;"
      >
        <b-img-lazy
          src="@/assets/images/salesChannel/lazada.svg"
          fluid
        />
      </b-aspect>
      <div class="ml-2">
        Lazada
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="connectedTiki"
      to="/channel/tiki"
      class="d-flex align-items-center"
      :class="{'active': currentChannel === 'tiki'}"
    >
      <b-aspect
        aspect="16:9"
        class="d-flex align-items-center"
        style="min-width: 60px; max-width: 60px;"
      >
        <b-img-lazy
          src="@/assets/images/salesChannel/tiki.svg"
          fluid
        />
      </b-aspect>
      <div class="ml-2">
        Tiki
      </div>
    </b-list-group-item>
    <b-list-group-item
      v-if="connectedFacebookLive"
      to="/channel/facebook-live"
      class="d-flex align-items-center"
      :class="{'active': currentChannel === 'facebook-live'}"
    >
      <b-aspect
        aspect="16:9"
        class="d-flex align-items-center"
        style="min-width: 60px; max-width: 60px;"
      >
        <b-img-lazy
          src="@/assets/images/salesChannel/facebook.svg"
          fluid
        />
      </b-aspect>
      <div class="ml-2">
        Facebook live
      </div>
    </b-list-group-item>
    <!-- <b-list-group-item
      to="/channel/tiki"
      class="d-flex align-items-center"
      :class="{'active': currentChannel === 'tiki'}"
    >
      <b-aspect
        aspect="16:9"
        class="d-flex align-items-center"
        style="min-width: 60px; max-width: 60px;"
      >
        <b-img-lazy
          src="@/assets/images/salesChannel/tiki.svg"
          fluid
        />
      </b-aspect>
      <div class="ml-2">
        Tiki
      </div>
    </b-list-group-item> -->
  </b-list-group>
</template>
<script>
/* eslint-disable func-names */
import {
  BImgLazy,
  BAspect,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BImgLazy,
    BAspect,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      currentChannel: null,
      channelDetail: null,
      connectedLazada: false,
      connectedShopee: true,
      connectedTiki: false,
      connectedFacebookLive: false,
    }
  },
  watch: {
    '$route.params.id': function () {
      this.currentChannel = this.$route.params.id
    },
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.currentChannel = this.$route.params.id
    }
    this.loadSiteInfo()
  },

  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.access_token_lazada !== '' && res.data.data.access_token_lazada !== null && res.data.data.access_token_lazada !== undefined) {
              this.connectedLazada = true
            }
            if (res.data.data.shop_id_shopee !== '' && res.data.data.shop_id_shopee !== null && res.data.data.shop_id_shopee !== undefined) {
              this.connectedShopee = true
            }
            if (res.data.data.token_live_facebook !== '' && res.data.data.token_live_facebook !== null && res.data.data.token_live_facebook !== undefined) {
              this.connectedFacebookLive = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
